.header {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.spinner {
  height: calc(100vh - 50px) !important;
}

.toolsArea { 
  display: inline-flex; 
  align-items: center;
}

.emptyPageHeader {
  justify-content: flex-end;
}

.mobileHeader {
  display: none;
}

.emptyPage {
  height: calc(100vh - 150px) !important;
}

.headerSelect {
  width: 290px !important;
}

.switchWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.strengthSwitchText {
  font-size: 14px;
  font-weight: 600;
  margin-right: 20px;
  text-transform: uppercase;
}

.chartWrapper {
  background-color: white;
  border-radius: 15px;
  height: calc(100vh - 150px)!important;
  min-height: 520px;
  position: relative;
  width: 100%;
}

.influenceLabel,
.yLabel,
.supportLabel,
.zeroXLabel,
.leftXLabel,
.rightXLabel {
  font-size: 18px;
  font-weight: 600;
  position: absolute;
}

.influenceLabel {
  left: 46%;
  top: 10px;
}

.yLabel {
  left: 49%;
  top: 40px;
}

.supportLabel {
  bottom: 10px;
  left: 47%
}

.zeroXLabel {
  bottom: 40px;
  left: 49.5%
}

.leftXLabel {
  bottom: 40px;
  left: calc(0% + 50px)
}

.rightXLabel {
  bottom: 40px;
  left: calc(100% - 60px)
}

.emptyPage {
  align-items: center;
  background-color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  min-height: 520px;
  width: 100%;
}

.exportBtn {
  align-items:center;
  border-radius: 10px;
  border: 2px solid #0E9AFC;
  cursor: pointer;
  display:flex;
  justify-content:center;
  height:36px;
  width: 50px;
}

.switchWrapperMobile {
  align-items: center;
  background-color: white;
  border: 2px solid #0E9AFC;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  height: 55px;
  padding-left: 11px;
  width: 270px;
}

.undecidedButton {
  align-items: center;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  right: 10px;
  position: absolute;
  padding: 5px;
  top: 10px;
  z-index: 8;
}

.noDataUsersIcon {
  height: 30px;
  width: 30px;
  z-index: 6
}

@media screen and (min-width: 360px) and (max-width: 1366px) {
  .chartWrapper {
    min-height: 500px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1000px) {
  .headerSelect {
    width: 250px !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 766px) {
  .chartWrapper {
    height: calc(100% - 100px) !important
  }
  
  .mobileHeader {
    display: block;
  }

  .spinner {
    height: 100vh !important;
  }

  .toolsArea {
    display: none;
  }

  .emptyPageHeader {
    justify-content: space-between;
  }

  .emptyPage {
    height: calc(100vh - 130px) !important;
    padding-bottom: 30px;
  }

  .headerSelect {
    width: 270px !important;
  }

  .zeroXLabel {
    left: 49%
  }

  .influenceLabel {
    left: 40%;
  }
  
  .yLabel {
    left: 48%;
  }
  
  .supportLabel {
    left: 43%
  }

  .noDataUsersIcon {
    right: 10px;
    position: absolute;
    top: 10px;
  }
}
