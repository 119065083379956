.form {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.inputLabel {
  color: #373853;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 15px;
}

.buttonsArea {
  align-self: flex-end;
  margin-top: 20px;
}

.primaryButton {
  margin-left: 15px;
}
