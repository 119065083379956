.wrapperFirstPart,
.wrapperSecondPart {
  position: absolute;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
  z-index: 5;
  border-radius: 10px;
}

.wrapperFirstPart {
  top:10px; 
  left:10px; 
}

.wrapperSecondPart {
  top:10px; 
  left:40px; 
}

.button {
  align-items:center;
  background-color:black;
  box-sizing: content-box;
  border-bottom: 1px solid white; 
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content:center;
  padding: 5px;
  width: 16px;
}

.button:hover {
  background-color: #515151;
}

.icon {
  max-width: 12px;
  max-height: 12px;
}
