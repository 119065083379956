.modalTitle {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 25px;
}

.errorMessage {
  color: #f44537;
}

.form {
  display: flex;
  flex-direction: column;
  width: 720px;
}

.inputsRow {
  display: flex;
  justify-content: space-between;
}

.colorPickerLabel {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.colorPicker {
  margin-bottom: 20px;
}

.buttonsArea {
  display: flex;
  justify-content: flex-end;
}
