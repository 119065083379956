.modalTitle {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 25px;
}

.errorMessage {
  color: #f44537;
}

 /* Form */
.formWrapper {
  display: flex;
  flex-direction: column;
  width: 700px;
}

.combinedInputsArea {
  display: flex;
  justify-content: space-between;
  width: 706px;
}

.slider {
  display: flex;
  flex-direction: column;
  width: 320px;
} 

.sliderLabel {
  align-self: flex-start;
  display: block;
  font-weight: 600;
  margin-left: '12px'
}

 .buttonsArea {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
