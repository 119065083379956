.wrapper {
  align-items: center;
  background-color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  min-height: 520px;
  width: 100%;
}

.header {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.chartContainer {
  background-color: transparent;
  background-image: none;
  border: none;
  height: auto;
  margin: 0px;
}

.chart {
  background-color: none;
  background-image: none;
}

.personCardWrapper {
  border: 3px solid #12C1E8;
  border-radius: 10px;
  height: 65px;
  width: 200px;
}

.cardNameArea {
  align-items: center;
  background-color: #12C1E8;
  display: flex;
  height: 50%;
  text-align: center;
}

.cardName {
  font-weight: 600;
  text-align: center;
  width: 75%;
}

.cardRoleArea {
  align-items: center;
  background-color: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  height: 50%;
  justify-content: center;
}

.cardIcon {
  justify-self: start;
  margin-right: 5px;
  width: 20px;
}
