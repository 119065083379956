.tableIcon {
  cursor: pointer;
}

.tableIsActiveColumn {
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-weight: 600;
  height: 70%;
  justify-content: center;
  width: 100%;
  margin-top: 7px;
}

.modalTitle {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 25px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 720px;
}

.inputsPair {
  display: flex;
  justify-content: space-between;
  width: 98%;
}

.buttonsArea {
  display: flex;
  justify-content: flex-end;
  width: 97%;
}

.rolesWrap {
  border: 2px solid black;
  border-radius:5px;
  display:flex;
  justify-content:space-around;
  margin-bottom:10px;
  padding:10px;
  position:relative;
  width:97%;
}

.roleAreaTitle {
  background-color: white;
  font-size: 13px;
  font-weight: 600;
  top: -13px;
  left: 10px;
  padding: 0px 4px;
  position: absolute;
}

.rolesWrapError {
  border: 2px solid #f44336;
}
.rolesWrapError .roleAreaTitle {
  color: #f44336;
}
.rolesErrorText {
  color: #f44336;
  font-size: 12px;
  margin-left: 15px;
  margin-top: -5px;
}

.generateBtn {
  border: 2px solid #0E9AFC;
  border-radius: 15px;
  cursor: pointer;
  font-weight: 600;
  right: 13px;
  padding: 3px;
  position: absolute;
  top: 13px;
}

.tenantsTable{
  border-radius: 12px;
  background: #FFF;
  border: 2px solid #D6D6D6;
  max-width: 700px;
  width: 100%;
}

.tenantsTable__header{
  padding: 14px 60px 14px 40px;
  display: flex;
  border-bottom: 1px solid #D6D6D6;
  justify-content: space-between;
}
.tenantsTable__row{
  display: flex;
  padding: 12px 53px 12px 40px;
  align-items: center;
  justify-content: space-between;
}
.tenantsTable__column{
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  min-width: 120px;
  display: flex;
  justify-content: center;
}
.tenantsTable__rowColumn{
  min-width: 120px;
  display: flex;
  justify-content: center;
}
.errorEmail{
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.66;
}