.header {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.pageTitle {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  margin-left: 20px;
}

.contentWrapper {
  background-color: white;
  border-radius: 15px;
  overflow-y: auto;
  padding: 45px 35px 45px 35px;
  min-height: 475px;
  width: 100%;
}

/* main info form */
.errorMessage {
  color: #f44537;
  font-size: 16px;
}

.mainInfoWrapper {
  display: grid;
  grid-template-columns: 720px 1fr;
}

.form,
.inputsArea {
  display: flex;
  flex-direction: column;
}

.inputsArea {
  width: 720px;
}

.datePickersArea {
  display: flex;
  justify-content: space-between;
  width: 98%;
}

.roleDescriptionArea {
  display: grid;
  grid-template-columns: 1fr;
  height: 170px;
  width: 100%;
}

.selectWrapper {
  justify-self: flex-end;
}

.buttonsArea {
  display: flex;
  justify-content: flex-end;
}

.successMessageModal {
  align-items: center;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  height: 180px;
  width: 450px;
}

.successMessage {
  font-size: 18px;
  padding-bottom: 45px;
}

.modalView.mainInfoWrapper {
  grid-template-columns: 1fr;
}

.modalView .inputsArea {
  width: 400px;
}

.modalView .datePickersArea {
  flex-direction: column;
  width: 100%;
}

.modalView .selectWrapper {
  justify-self: flex-start;
}

/* contacts table */

.tableWrapper {
  background-color: white;
  border-radius: 15px;
  height: 85%;
  margin-top: 85px;
  margin-bottom: 20px;
  position: relative;
  width: 100%; 
  text-align: center !important;
}
