.header {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.emptyPageHeader {
  justify-content: flex-end;
}

.mobileHeader {
  display: none;
}

.emptyPage {
  height: calc(100vh - 150px) !important;
  min-height: 500px;
}

.toolsArea { 
  display: inline-flex; 
  align-items: center;
}

.switchWrapper {
  display: inline-flex; 
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}

.headerSelect {
  width: 290px !important;
}

.switchWrapperMobile {
  align-items: center;
  background-color: white;
  border: 2px solid #0E9AFC;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  height: 55px;
  padding-left: 11px;
  width: 270px;
}


@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .headerSelect {
    width: 250px !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 1000px) {
  .headerSelect {
    width: 150px !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 1366px) {
  .spinner {
    height: calc(100vh - 50px) !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 766px) {
  .mobileHeader {
    display: block;
  }

  .emptyPageHeader {
    justify-content: space-between;
  }

  .toolsArea {
    display: none;
  }

  .spinner {
    height: 100vh !important;
    width: 100vw !important;
  }

  .emptyPage {
    height: calc(100vh - 130px) !important;
    padding-bottom: 30px;
  }

  .headerSelect {
    width: 270px !important;
  }
}
