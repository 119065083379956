.WorkerCardWrapper {
  height: 200px;
  width: 200px !important;
  border-radius: 50% !important;
}

.avatar {
  width: 50px;
  height: 50px;
}
