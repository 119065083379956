.text {
  font-size: 20px;
  text-align: center;
}

.btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
