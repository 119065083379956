.header {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.spinner {
  height: calc(100vh - 50px) !important;
}

.toolsArea {
  display: inline-flex; 
  align-items: center;
}

.mobileHeader {
  display: none;
}

.emptyPageHeader {
  justify-content: flex-end;
}

.emptyPage {
  height: calc(100vh - 150px) !important;
  min-height: 520px;
}

.headerSelect {
  width: 290px !important;
}

.wrapper {
  background-color: white;
  border-radius: 15px;
  min-height: 520px;
  width: 100%;
  height: calc(100vh - 150px)!important;
}

.tableViewWrapper {
  width: 90%; 
}

.tableViewTitle {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: space-between;
  padding: 40px 0px 15px 0px;
}

.matrixTable {
  border-radius: 15px !important;
  height: calc(100vh - 280px);
  overflow-y: auto;
  max-width: calc(100vw - 300px);
}

.matrixTable::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
}
.matrixTable::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.tableHeader {
  font-size: 18px !important;
  font-weight: 600 !important; 
  min-width: 200px;
}

.optionsPopUp {
  background-color: white;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),
              0px 8px 10px 1px rgba(0,0,0,0.14),
              0px 3px 14px 2px rgba(0,0,0,0.12);
  border-radius: 5px;
  font-weight: 400;
  right: -50px;
  min-width: 300px;
  padding: 12px 7px;
  position: absolute;
  top: 34px;
  z-index: 1000;
}

.tableIsActiveColumn {
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-weight: 600;
  height: 70%;
  justify-content: center;
  width: 100%;
}


.matrixViewWrapper {
  align-items: center;  
  background-color: white;
  border-radius: 15px !important;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.matrixViewSubWrapper {
  background-color: white;
  height: 85%;
  top: 20px;
  position: absolute;
  width: 90%;
}

.arrowRight {
  bottom: 15px;
  right: 10px;
  position: absolute; 
  z-index: 7;
  width: calc(100vw - 350px);
}

.arrowUp {
  left: 25px;
  position: absolute;
  bottom: 25px;
  z-index: 7;
}

.influence {
  bottom: 50%;
  font-size: 18px;
  font-weight: 600;
  left: -35px;
  position: absolute;
  transform: rotate(270deg);
  z-index: 100;
}

.keepSatisfiedTitle,
.manageCloselyTitle,
.keepInformedTitle,
.monitorTitle { 
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  position: absolute;
  text-align: center;
  width: 160px;
  z-index: 0;
}


.keepSatisfiedTitle {
  background-color: rgba(18, 193, 232, 0.1);
  border: 1px solid rgba(18, 193, 232, 0.5);
  top: 15px;
  left: 18%;
  z-index: 100;
}

.manageCloselyTitle {
  background-color: rgba(194, 232, 18, 0.1);
  border: 1px solid rgba(194, 232, 18, 0.5);
  right: 20%;
  top: 15px;
  z-index: 100;
}

.keepInformedTitle {
  background: rgba(135, 162, 13, 0.1);
  border: 1px solid rgba(135, 162, 13, 0.5);
  bottom: 50px;
  right: 20%;
  z-index: 100;
}

.monitorTitle {
  background: rgba(18, 129, 232, 0.1);
  border: 1px solid rgba(18, 129, 232, 0.5);
  bottom: 50px;
  left: 18%;
  z-index: 100;
}

.switchWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  text-align: center;
}

.switchWrapperMobile {
  align-items: center;
  background-color: white;
  border: 2px solid #0E9AFC;
  border-radius: 10px;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  height: 55px;
  padding-left: 11px;
  width: 270px;
}

.noDataUsersIcon {
  height: 30px;
  width: 30px;
  z-index: 6
}

.undecidedButton {
  align-items: center;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  right: 10px;
  position: absolute;
  padding: 5px;
  top: 10px;
  z-index: 8;
}

@media screen and (min-width: 360px) and (max-width: 1366px) {
  .arrowRight {
    width: calc(100vw - 150px);
  }

  .matrixTable {
    max-width: calc(100vw - 85px);
  }
}

@media screen and (min-width: 767px) and (max-width: 1000px) {
  .headerSelect {
    width: 250px !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 766px) {
  .wrapper {
    height: calc(100% - 130px)!important;
  }

  .mobileHeader {
    display: block;
  }

  .toolsArea {
    display: none;
  }

  .headerSelect {
    width: 270px !important;
  }

  .emptyPageHeader {
    justify-content: space-between;
  }

  .emptyPage {
    height: calc(100vh - 130px) !important;
  }

  .arrowRight {
    width: calc(100vw - 50px);
  }

  .manageCloselyTitle,
  .keepInformedTitle,
  .keepSatisfiedTitle,
  .monitorTitle {
    font-size: 12px;
    padding: 0px;
    width: 130px;
  }

  .keepSatisfiedTitle,
  .monitorTitle {
    left: 40px;
  }
  
  .manageCloselyTitle,
  .keepInformedTitle {
    right: 30px;
  }

  .noDataUsersIcon {
    right: 0px;
  }

  .matrixTable {
    max-width: calc(100vw - 10px);
  }

  .noDataUsersIcon {
    right: 0px;
    position: absolute;
    top: 10px;
  }
}
