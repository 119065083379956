.header {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.pageTitle {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  margin-left: 20px;
}

.formWrapper {
  background-color: white;
  border-radius: 15px;
  overflow-y: auto;
  padding: 45px 35px 45px 35px;
  min-height: 475px;
  width: 100%;
}
