.uploadPreview {
  position: relative;
}

.uploadPreview .previewTools {
  display: none;
}

.uploadPreview:hover .previewTools {
  align-items: center;
  background: linear-gradient(0deg, rgba(12, 12, 12, 0.5), rgba(12, 12, 12, 0.5));
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.uploadPreview:hover .previewTools svg {
  cursor: pointer;
}
.draggerText span:first-child{
  color: #0E9AFC;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px
}
.draggerText span:nth-child(3){
  color: #667085;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.draggerImg{
  border-radius: 28px;
  background: #F2F4F7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.draggerImgInner{
  border-radius: 28px;
  background: rgba(238, 237, 237, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}
