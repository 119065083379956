.emailBlock {
  display: grid;
  grid-template-columns: 60% 5%;
}

.emailInputRow {
  display: grid;
  grid-template-columns: 83% 25% 2%;
}

.addInputBtn {
  display: inline-block;
  margin-left: 10px;
  margin-top: 15px;
}

.addInputIcon,
.removeInputIcon {
  cursor: pointer;
}

.radioArea {
  margin-top: 5px;
  width: 70px;
  margin-left: 15px;
}

