.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.buttonsArea {
  align-self: flex-end;
  margin-top: 20px;
}

.successMessageWrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.successMessage {
  font-size: 18px;
  padding-bottom: 45px;
}
