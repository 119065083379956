.tableWrapper {
  height: 300px;
  margin-top: 25px;
  margin-bottom: 20px;
  text-align: center !important;
  width: 100%; 
}

.tableIcon {
  cursor: pointer;
}
