.form {
  height: 100%;
  position: relative;
  width: 100%;
}

.tabsWrapper {
  height: auto;
}

.submitArea {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.buttonsArea {
  align-items: center;
  color: #109afc;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
}

.buttons {
  display: flex;
  justify-content: center;
}

.successMessageModal {
  align-items: center;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  height: 180px;
  width: 450px;
}

.successMessage {
  font-size: 18px;
  padding-bottom: 45px;
}
