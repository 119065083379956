.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100px;
}

.wrapper {
  background-color: white;
  border-radius: 15px;
  min-height: 520px;
  padding: 45px 40px 45px 40px;
}

.pageTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
}

.tableWrapper {
  height: 70%;
  margin-top: 75px;
  margin-bottom: 20px;
  text-align: center !important;
  width: 100%; 
}

.buttonsArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  width: 100%;
}
