.contentWrapper h1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
}

.commentsWrapper {
  border: 1px solid #F5F6F6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-height: 300px;
  overflow: auto;
}

.comment {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.author {
  color: rgba(0, 0, 0, 0.45);
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  line-height: 18px;
  margin-bottom: 15px;
}

.modificationIcons svg {
  cursor: pointer;
}

.commentText {
  font-size: 12px;
  line-height: 150%;
}

.projectViewCheckbox {
  margin: 20px 0px;
}

.btnArea {
  display: flex;
  justify-content: flex-end;
}

.btnArea button {
  width: 87px;
}

.btnArea button:first-of-type {
  margin-right: 10px;
}
