.wrapper {
  background-color: #eef8ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95vh;
}

.backArrow {
  align-items: center;
  color: black;
  display: flex;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 25px;
  text-decoration: none;
}

.logo {
  justify-self: center;
  margin: 0 auto;
  width: 150px;
}
