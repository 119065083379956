.wrapper {
  align-items: center;
  background-color: #eef8ff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  min-height: 550px;
}

.contentWrapper {
  align-items: center;
  background-color: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 100px 40px 100px;
}

.companyLogo {
  margin-bottom: 25px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
}

.description {
  color: #969696;
  font-size: 14px;
  line-height: 150%;
  max-width: 370px;
  margin-bottom: 20px;
  text-align: center;
}

.sendAgain {
  align-items: center;
  cursor: pointer;
  color: #006BBE;
  display: flex;
  text-decoration: underline;
}

.errorMessage {
  color: #f44336;
  font-weight: 600;
  margin-bottom: 10px;
}

@media screen and (min-width: 428px) and (max-width: 649px) {
  .contentWrapper {
    padding: 60px 20px 60px 20px;
  }
}

@media screen and (min-width: 300px) and (max-width: 427px) {
  .wrapper {
    min-height: 250px;
  }

  .contentWrapper {
    padding: 20px 20px 20px 20px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 360px) and (max-width: 766px) {
  .wrapper {
    min-height: auto;
  }
}
