.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100px;
}

.wrapper {
  background-color: white;
  border-radius: 15px;
  min-height: 520px;
  padding: 45px 40px 45px 40px;
}

.titleArea {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.pageTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
}

.tableWrapper {
  height: 85%;
  margin-top: 65px;
  margin-bottom: 20px;
  text-align: center !important;
  width: 100%; 
}

.tableIcon {
  cursor: pointer;
}
