.wrapper {
  align-items: center;
  background-color: #eef8ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  min-height: 550px;
  overflow: scroll;
}

.formArea {
  align-items: center;
  background-color: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 100px 40px 100px;
}

.companyLogo {
  margin-bottom: 25px;
}

.formTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
}

.formWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.input {
  width: 330px;
}

.loginError {
  color: #f44336;
  font-weight: 600;
  margin-bottom: 10px;
}

.formText{
  color: #969696;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 20px;
  max-width: 351px;
  width: 100%;
}

.roleBack{
  margin-top: 20px;
  cursor: pointer;
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  text-decoration-line: underline;
}

@media screen and (min-width: 428px) and (max-width: 649px) {
  .formArea {
    padding: 60px 20px 60px 20px;
  }
}

@media screen and (min-width: 300px) and (max-width: 427px) {
  .formArea {
    padding: 20px 20px 20px 20px;
  }

  .formTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 360px) and (max-width: 766px) {
  .wrapper {
    min-height: auto;
  }
}
