.reactFlowWrapper {
  border: 1px solid #EEEEFF;
  border-radius: 15px;
  overflow: hidden; 
  position: relative;
}

.connectingMessage {
  background-color: #dbf0fe;
  border-bottom-left-radius: 15px;
  font-size: 16px;
  right: 0px;
  padding: 15px;
  position: absolute;
  width: 200px;
  z-index: 100;
}

.cancelConnecting {
  cursor: pointer;
}

.exportHeader,
.exportFooter {
  display: none;
}

.undoButton {
  align-items: center;
  background-color: #1890FF;
  border-radius: 2px;
  cursor: pointer;
  color: white;
  display: flex;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  padding: 1px 8px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 80px;
  z-index: 10;
}

@media print {
  .miniMapNavigation,
  .controlPanel,
  .annotations {
    display: none !important;
  }

  .exportHeader,
  .exportFooter {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    justify-content: space-between;
    padding: 10px 30px;
  }

  .exportFooter {
    margin-top: 55px;
  }

  .exportHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .reactFlowWrapper {
    border: 1px solid transparent !important;
    height: 100% !important;
  }

  .canvas {
    height: 600px !important;
  }
  @page { size: landscape; }
}

@media screen and (min-width: 767px) and (max-width: 1366px) {
  .reactFlowWrapper {
    height: calc(100vh - 150px) !important;
    width: calc(100vw - 85px) !important;
  }

  .canvas {
    width: calc(100vw - 85px);
  }
}

@media screen and (min-width: 360px) and (max-width: 766px) {
  .reactFlowWrapper {
    width: calc(100vw - 10px) !important;
  }

  .canvas {
    width: calc(100vw - 10px);
  }

  .undoButton {
    display: none;
  }
}

