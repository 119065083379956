.form {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.inputLabel {
  color: #373853;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 15px;
}

.buttonsArea {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.connectionDeletedMessage {
  align-items: center;
  display: flex;
  font-size: 18px;
  flex-direction: column;
  justify-content: center
}
