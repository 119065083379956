.doubleInputsRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 99%;
}

.notesInput {
  display: grid;
  grid-template-columns: 1fr;
  height: 150px;
  width: 100%;
}

.inputsWrapper {
  width: 60%;
}
