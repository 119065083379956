.header {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.pageTitle {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  margin-left: 20px;
}

.buttonsArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.tabsWrapper {
  background-color: white;
  border-radius: 15px;
  overflow-y: auto;
  padding: 45px 35px 45px 35px;
  min-height: 475px;
  width: 100%;
}

/* users table */
.titleArea {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  padding-top: 4px;
}

.tableWrapper {
  height: 85%;
  text-align: center !important;
  width: 100%; 
  height: 400px;
}

.tableIcon {
  cursor: pointer;
}

.tableIsActiveColumn {
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-weight: 600;
  height: 70%;
  justify-content: center;
  width: 100%;
}

/* MainDataForm */

.form {
  display: grid;
  grid-template-columns: 700px 1fr;
  width: 100%;
}

.inputsArea {
  display: flex;
  flex-direction: column;
  width: 700px;
}

.datePickerArea {
  display: flex;
  justify-content: space-between;
  width: 98%;
}

.logoArea {
  display: flex;
  justify-content: flex-end;
  height: 140px;
}

.successMessageModal {
  align-items: center;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  height: 180px;
  width: 450px;
}

.successMessage {
  font-size: 18px;
  padding-bottom: 45px;
}
