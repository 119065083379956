.header {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 100px;
}

.wrapper {
  background-color: white;
  border-radius: 15px;
  min-height: 520px;
  padding: 45px 40px 45px 40px;
  overflow: auto;
}

.pageTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
}

.mainData {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.avatarArea {
  justify-self: center;
}

.inputsArea {
  display: flex;
  flex-direction: column;
  width: 380px;
}

.buttonsArea {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.generateBtn {
  border: 2px solid #0E9AFC;
  border-radius: 15px;
  cursor: pointer;
  font-weight: 600;
  right: 13px;
  padding: 3px;
  position: absolute;
  top: 13px;
  z-index: 1000;
}

.synchronizationWrapper {
  display: flex;
  flex-direction: column;
}

.successMessageModal,
.synchronizationModal {
  align-items: center;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  min-height: 180px;
  width: 450px;
}

.successMessage,
.synchronizationMessage {
  font-size: 18px;
  padding-bottom: 45px;
}

.removeAvatar {
  cursor: pointer;
  margin-top: 6px;
  text-align: center;
}

.removeAvatar:hover {
  text-decoration: underline;
}
