/* select worker */
.selectWorker {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.buttonsArea {
  align-self: flex-end;
  margin-top: 30px;
}

/* new worker form */
.inputsArea {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.formFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.submitBtn {
  margin-left: 15px;
}

.sliderLabel {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
  width: 75%;
}

.errorMessage {
  color:red;
  font-size:16px;
  font-weight:600;
  margin-top:15px;
  text-align:center;
}

.successMessageModal {
  align-items: center;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  height: 180px;
  width: 450px;
}

.successMessage {
  font-size: 18px;
  padding-bottom: 45px;
}
