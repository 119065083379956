.successMessageModal {
  align-items: center;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  height: 180px;
  width: 450px;
}

.successMessage {
  font-size: 18px;
  padding-bottom: 45px;
}
