.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 auto;
}

.buttonsArea {
  align-self: flex-end;
}

.successMessage {
  align-items: center;
  display: flex;
  font-size: 20px;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 300px) and (max-width: 427px) {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 220px;
  }

  .buttonsArea {
    align-self: center;
  }
}
