.wrapper {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  min-height: 600px;
  width: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.navBar {
  background-color: transparent;
  width: 230px;
}

.toolsAreaMobile {
  display: none;
}

.companyLogo {
  margin-bottom: 30px;
  margin-top: 15px;
  overflow: visible !important;
  object-fit: contain;
  width: 214px;
  height: 81px;
}

.menu {
  background-color: transparent;
}

.link {
  text-decoration: none;
  color: black !important;
}

.menu {
  margin-bottom: 20px;
}

.InfoAlertWrapper {
  background-color: transparent;
  box-shadow: 
    0px 2px 4px rgba(0, 0, 0, 0.14), 
    0px 3px 4px rgba(0, 0, 0, 0.12), 
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 10px;
  min-height: 100px;
  overflow-y: auto;
  padding: 12px;
  position: relative;
  text-align: center;
  width: 90%;
}

.closeInfoAlertIcon {
  cursor: pointer;
  right: 10px;
  position: absolute;
  top: 10px;
}

.tabletMenuWrapper {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px 0px;
  overflow: hidden;
  width: 50px;
}

.mobileMenuWrapper {
  display: none;
}

@media screen and (min-width: 767px) and (max-width: 1366px) {
  .companyLogo {
    margin-bottom: 5px;
    margin-top: 0px;
  }

  .tabletMenuWrapper {
    position: relative;
  }
}

@media screen and (min-width: 360px) and (max-width: 766px) {
  .wrapper {
    display: none;
  }

  .toolsAreaMobile {
    display: block;
    margin-left: 10px;
  }

  .mobileMenuWrapper {
    align-items: center;
    display: flex;
  }

  .mobileMenuWrapper .companyLogo {
    height: 50px;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 7px;
    max-width: 150px;
    width: auto;
  }
}
