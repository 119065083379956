.wrapper {
  align-items: center;
  background-color: #eef8ff;
  display: grid;
  font-size: 16px;
  grid-template-columns: 1fr 1fr;
  min-height: 55px;
  width: calc(100vw - 20px);
}

.infoArea {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.iconsArea {
  justify-self: flex-end;
  padding-right: 100px;
}

.icon {
  cursor: pointer;
}

@media screen and (min-width: 650px) and (max-width: 1050px) {
  .wrapper {
    grid-template-columns: 3fr 1fr;
  }
  
  .iconsArea {
    padding-right: 30px;
  }
}

@media screen and (min-width: 300px) and (max-width: 649px) {
  .wrapper {
    grid-template-columns: 1fr;
  }

  .infoArea {
    grid-template-columns: 1fr;
    text-align: center;
    margin-bottom: 7px;
  }

  .logo {
    margin: 0 auto;
    margin-bottom: 7px;
  }
  
  .iconsArea {
    margin: 0 auto;
    padding-right: 0px;
  }
}

@media screen and (min-width: 300px) and (max-width: 649px) {
  .wrapper {
    display: none;
  }
}
