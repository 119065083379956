.wrapper {
  display: grid;
  grid-template-columns: 1fr 350px;
}

.doubleInputsRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 99%;
}

.uploadWrapper {
  display: flex;
  justify-content: center;
}

.uploadButton {
  align-items: center;
  background-color: rgba(196, 196, 196, 0.2);
  border: 2px dashed black;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 147px;
  justify-content: center;
  width: 200px;
}

.uploadButtonText {
  border: 2px solid #0E9AFC;
  border-radius: 10px;
  font-size: 8px;
  font-weight: 600;
  padding: 4px;
  margin-bottom: 5px;
}

.addAvatarImg {
  width: 40%
}

.avatar {
  border: 2px dashed black;
  border-radius: 30px;
  cursor: pointer;
  height: 300px;
  margin-bottom: 40px;
  object-fit: cover;
  width: 330px;
}
