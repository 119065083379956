.annotationsWrapper {
  background-color: white;
  border: 2px solid #F5F6F6;
  box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.08), 4px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  bottom: 10px;
  right: 10px;
  padding: 10px 10px;
  position: absolute;
  max-height: 190px;
  overflow-y: auto;
  width: 330px;
  z-index: 5;
}

.annotationsWrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.annotationsWrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.annotationItemWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
}

.annotationItemWrapper.selected {
  background-color: #eef8ff;
}

.author {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 18px;
}

.annotationItemIcon {
  cursor: pointer;
  height: 13px;
  width: 13px;
}

.annotationText {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  line-height: 150%;
}

.annotationMobileIcon {
  bottom: 50px;
  right: 20px;
  position: absolute;
  z-index: 10;
}

.annotationMapTooltip {
  background-color: #eef8ff;
  bottom: 0px;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  left: 10px;
  max-width: 300px;
  padding: 10px;
  position: absolute;
  width: max-content;
}

.annotationMapTooltip .author {
  color: black;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-bottom: 7px;
}

@media screen and (min-width: 360px) and (max-width: 766px) {
  .annotationsWrapper {
    left: 10px;
    width: 230px;
    z-index: 15;
  }
}
